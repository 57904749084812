/**
 * (c) 2015 Hendrik Runte.
 * Take it, if you wish.
 */

/**
 * Class to render and navigate through a slideshow with CSS transforms.
 * Will not work with MSIE 9 or lower.
 * @class Slides
 * @constructor
 */
var Slides = function(){
	this.Index = 0;
	this.Slideshow = $('div.slides');
	this.Slides = this.Slideshow.find('section');
	this.Matte = $('#matte');
	this.Dots = [];
	this.AutoPlayInterval = null;

	this.Slides
		.addClass('right');
	$(this.Slides[0])
		.addClass('onscreen')
		.removeClass('right');
	this.bindNavigationActions()
		.displayDots()
		.parseLocationHash();
};

/**
 * Binds the navigation elements with event listeners.
 * @return {Object} The instance itself.
 */
Slides.prototype.bindNavigationActions = function(){
	var self = this;
	$('a.slides-navigation.previous').on('click', function(eventObject){
		self.shiftPrevious();
		eventObject.preventDefault();
	});
	$('a.slides-navigation.next').on('click', function(eventObject){
		self.shiftNext();
		eventObject.preventDefault();
	});
	return self;
};

/**
 * Unbinds the navigation elements.
 * @return {Object} The instance itself.
 */
Slides.prototype.unbindNavigationActions = function(){
	$('a.slides-navigation, a.dots').off();
	$('a.slides-navigation, div.slides-dots').hide();
	return this;
};

/**
 * Creates navigational dots at the very bottom.
 * @return {Object} The instance itself.
 */
Slides.prototype.displayDots = function(){
	var self = this;
	if (self.Slides.length) {
		var Slides_dots = $('<div></div>')
			.addClass('slides-dots')
			.appendTo(self.Slideshow);
		for (var i=0, max_i=self.Slides.length; i<max_i; i+=1) {
			self.Dots[i] = $('<a></a>').attr({
				'href': '#!/slide:'+i,
				'title': i
			}).addClass('dots')
			.addClass(i === self.Index ? 'selected' : 'void')
			.appendTo(Slides_dots)
			.on('click', {i: i}, function(eventObject){
				self.shiftToSlide(eventObject.data.i);
				eventObject.preventDefault();
			});
		}
	}
	return self;
};

/**
 * Writes the browser URL and manipulates the browser history.
 * @method  updateLocationHash
 * @return {Object} The instance itself.
 */
Slides.prototype.updateLocationHash = function(){
	window.history.pushState(null, null, '#!/slide:'+this.Index);
};

/**
 * Reads window.location.hash to navigate to a specific slide.
 * @return {Object} The instance itself.
 */
Slides.prototype.parseLocationHash = function(){
	var parts = window.location.hash.split('#!/slide:');
	if (typeof(parts[1]) !== 'undefined') {
		this.shiftToSlide(parseInt(parts[1], 10));
	}
	return this;
};

/**
 * Updates the dots display.
 * @return {Object} The instance itself.
 */
Slides.prototype.updateDots = function(){
	$('a.dots').removeClass('selected');
	for (var i=0, max_i=this.Dots.length; i<max_i; i+=1) {
		if (i === this.Index) {
			this.Dots[i].addClass('selected');
		}
	}
	return this;
};

/**
 * Shifts to the given slide with number _slide_index.
 * @method shiftToSlide
 * @param  {Number} _slide_index Number of the slide to be selected.
 * @return {Object}              The instance itself;
 */
Slides.prototype.shiftToSlide = function(_slide_index) {
	var i = null;
	_slide_index = _slide_index || 0;
	if (this.Index !== _slide_index) {
		if (this.Index > _slide_index) {
			for(i=this.Index; i>_slide_index; i-=1) {
				this.shiftPrevious();
			}
		}
		if (this.Index < _slide_index) {
			for(i=this.Index; i<_slide_index; i+=1) {
				this.shiftNext();
			}
		}
	}
	return this;
};

/**
 * Shifts the slideshow to the next slide.
 * @method  shiftNext
 * @param  {Function} _transitionend_callback Callback function fired once being successful.
 * @return {Object} The instance itself.
 */
Slides.prototype.shiftNext = function(_transitionend_callback){
	this.Index = (this.Index+1 < this.Slides.length) ? this.Index+1 : 0;
	this.updateDots().updateLocationHash();
	$('section.left')
		.removeClass('animated left')
		.addClass('right');
	$('section.onscreen')
		.removeClass('onscreen')
		.addClass('animated left');
	$(this.Slides[this.Index])
		.removeClass('right quick')
		.addClass('animated onscreen')
		.on('transitionend webkitTransitionEnd', function(){
			if ($.isFunction(_transitionend_callback)) {
				_transitionend_callback();
			}
		});
	return this;
};

/**
 * Shifts the slideshow to the previous slide.
 * @param  {Function} _transitionend_callback Callback function fired once being successful.
 * @return {Object} The instance itself.
 */
Slides.prototype.shiftPrevious = function( _transitionend_callback){
	this.Index = (this.Index-1 >= 0) ? this.Index-1 : this.Slides.length-1;
	this.updateDots().updateLocationHash();
	$('section.right')
		.removeClass('animated right')
		.addClass('left');
	$('section.onscreen')
		.removeClass('onscreen')
		.addClass('animated right');
	$(this.Slides[this.Index])
		.removeClass('left quick')
		.addClass('animated onscreen')
		.on('transitionend webkitTransitionEnd', function(){
			if ($.isFunction(_transitionend_callback)) {
				_transitionend_callback();
			}
		});
	return this;
};

/**
 * Starts automatic shifting to each next slide.
 * @param {Number} _interval Time in ms to wait until next step. Defaults to 5000ms.
 * @return {Object} The instance itself.
 */
Slides.prototype.startAutoPlay = function(_interval){
	_interval = _interval || 5000;
	this.unbindNavigationActions();
	this.AutoPlayInterval = window.setInterval(function(_this){
		_this.shiftNext();
	}, _interval, this);
	return this;
};

/**
 * Stops the auto play immediately.
 * @return {Object} The instance itself.
 */
Slides.prototype.stopAutoPlay = function(){
	window.clearInterval(this.AutoPlayInterval);
	this.bindNavigationActions();
	return this;
};